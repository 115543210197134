/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal, Input, Form, Select } from "antd";
import { FormInstance } from "antd/es/form";
import { Goal } from "../../../api/goals";

type IProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  formValues: any;
  form: FormInstance<any>;
  onFinish: (e: Goal) => void;
  isEditing: boolean;
};

const jobCategories = [
  { value: "software", label: "💻 Software" },
  { value: "data_science", label: "📊 Data Science" },
  { value: "product_management", label: "💡 Product Management" },
  { value: "finance", label: "💰 Finance" },
  { value: "consulting", label: "👥 Consulting" },
  { value: "marketing", label: "📣 Marketing" },
  { value: "sales", label: "💼 Sales" },
  { value: "design", label: "🎨 Design" },
  { value: "customer_support", label: "🎧 Customer Support" },
  { value: "others", label: "🔹 Others" },
];

const CreateModal = ({
  open,
  setOpen,
  onFinish,
  form,
  formValues,
  isEditing,
}: IProps) => {
  return (
    <Modal
      width={550}
      open={open}
      title={isEditing ? "Edit a job application" : "Add a new job application"}
      okText={isEditing ? "Update" : "Create"}
      cancelText="Cancel"
      okButtonProps={{ autoFocus: true, htmlType: "submit" }}
      onCancel={() => setOpen(false)}
      destroyOnClose
      modalRender={(dom) => (
        <div>
          <Form
            layout="vertical"
            form={form}
            name="form_in_modal"
            initialValues={{ modifier: "public" }}
            clearOnDestroy
            onFinish={(values) =>
              onFinish({
                ...formValues,
                ...values,
              })
            }
          >
            {dom}
          </Form>
        </div>
      )}
    >
      <p className="mb-4">
        Share your job position and company details with the AI to create
        intelligent interview plans.
      </p>
      <Form.Item
        name="category"
        label="Job Category"
        rules={[{ required: true, message: "Please select a job category!" }]}
      >
        <Select
          placeholder="Select a job category"
          options={jobCategories}
        />
      </Form.Item>
      <Form.Item
        name="position"
        label="Position"
        rules={[{ required: true, message: "Please input the position!" }]}
      >
        <Input placeholder="Enter your job position. e.g. Software Engineer" />
      </Form.Item>
      <Form.Item
        name="company"
        label="Company"
        rules={[{ required: true, message: "Please input the company name!" }]}
      >
        <Input placeholder="Enter the company name. e.g. Google" />
      </Form.Item>
      <Form.Item name="company_details" label="Company Details">
        <Input.TextArea
          showCount
          maxLength={5000}
          placeholder="Copy and paste the company description here"
          rows={4}
        />
      </Form.Item>
      <Form.Item name="job_description" label="Job Description">
        <Input.TextArea
          maxLength={5000}
          showCount
          rows={4}
          placeholder="Copy and paste the job description here"
        />
      </Form.Item>
    </Modal>
  );
};

export default CreateModal;
