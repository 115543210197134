import React, { useState, useCallback, useRef, useEffect } from "react";
import { Button, Card, Collapse, CollapseProps, Space } from "antd";
import { IProps } from "../types";
import useScreenSharing from "../hooks/useScreenSharing";

const items: CollapseProps["items"] = [
  {
    key: "1",
    label: "Try Yourself",
    children: (
      <p>
        The easiest way is to use a YouTube mock interview video. Open any mock
        interview video on the browser and share screen with the tab that the
        YouTube video is opened in. Here are some recommended YouTube videos to
        try our interview copilot on:
        <ul>
          <li>
            <a
              href="https://www.youtube.com/watch?v=2zKsBfsrxrs&t=54s"
              target="_blank"
              rel="noopener noreferrer"
            >
              Interview Simulation
            </a>
          </li>
        </ul>
        Feel free to use any industry specific mock interview videos you can
        find that fit your specific needs.
      </p>
    ),
  },
  {
    key: "2",
    label: "Connect with Zoom",
    children: (
      <div>
        <p>
          To connect interview copilot with Zoom, you have to launch Zoom in the
          browser:
        </p>
        <ol>
          <li>Open the Zoom meeting link.</li>
          <li>When asked whether to open the Zoom app, select 'Cancel'.</li>
          <li>Select 'Join from Your Browser'.</li>
          <li>
            If you cannot see the 'Join from Your Browser' button, select
            'Launch Meeting', then select 'Cancel' when asked to open the Zoom
            app. You may have to repeat 'Launch Meeting' and 'Cancel' a few
            times before seeing the 'Join from Your Browser' button.
          </li>
          <li>
            Once the Zoom meeting is launched in the browser, navigate back to
            AceInterview. Share screen with the tab that the Zoom meeting is
            opened in. Remember to also share the tab audio by toggling the
            switch.
          </li>
        </ol>
      </div>
    ),
  },
  {
    key: "3",
    label: "Connect with Microsoft Teams",
    children: (
      <div>
        <p>
          To connect interview copilot with Teams, you have to launch Teams in
          the browser:
        </p>
        <ol>
          <li>Open the Teams meeting link.</li>
          <li>Select 'Continue on this browser'.</li>
          <li>
            Once the Teams meeting is launched in the browser, navigate back to
            AceInterview. Share screen with the tab that the Teams meeting is
            opened in. Remember to also share the audio by toggling the switch.
          </li>
        </ol>
      </div>
    ),
  },
  {
    key: "4",
    label: "Connect with Google Meet",
    children: (
      <p>
        Google Meet is by default launched in the browser. To connect interview
        copilot with Google Meet, simply share screen with the tab that the
        Google Meet meeting is opened in. Remember to also share the audio by
        toggling the switch.
      </p>
    ),
  },
  {
    key: "5",
    label: "Connect with phone interviews",
    children: (
      <div>
        <p>
          To connect your interview copilot with phone interviews, follow these
          steps using Google Voice:
        </p>
        <ol>
          <li>Open Google Voice in your browser.</li>
          <li>
            Test your microphone and speakers to ensure they are working
            correctly.
          </li>
          <li>
            To enable AceInterview to hear the call, share your Google Voice
            screen with AceInterview during the interview. This allows
            AceInterview to capture audio and provide real-time support.
          </li>
        </ol>
        <p>
          By following these steps, you can seamlessly integrate AceInterview
          into your phone interviews for optimal performance.
        </p>
      </div>
    ),
  },
  {
    key: "6",
    label: "Connect with Other Video Conferencing Tools",
    children: (
      <p>
        To connect interview copilot with any other video conferencing tool, you
        will need to launch the video conferencing tool in the browser. Then,
        navigate back to AceInterview. Share screen with the tab that the video
        conferencing tool is opened in. Remember to also share the audio by
        toggling the switch.
      </p>
    ),
  },
];

type IProps = {
  onNext: (value: boolean) => void;
  onScreenShareParams: (e: any) => void;
  onLaunchInterview: () => void;
  launchInterviewLoading: boolean;
};
const Next3 = ({
  onNext,
  onScreenShareParams,
  onLaunchInterview,
  launchInterviewLoading,
}: IProps) => {
  useScreenSharing();
  const {
    screenStream: videoStream,
    audioStream,
    combinedAudioStream,
    isSharing,
    handleStart,
    handleStop,
    videoRef,
    screenAudioStatus,
    microphoneStatus,
  } = useScreenSharing();

  useEffect(() => {
    if (videoRef.current && videoStream) {
      videoRef.current.srcObject = videoStream;
    }
  }, [videoStream]);

  useEffect(() => {
    onScreenShareParams({
      isSharing,
      audioStream,
      videoStream,
      combinedAudioStream,
      handleStop,
    });
  }, [isSharing, audioStream, videoStream, combinedAudioStream, handleStop]);

  return (
    <Card className="h-full">
      <Card title="Connect with Interview Copilot">
        <div className="flex flex-col items-center gap-4">
          <p className="text-gray-500 text-center">
            To ensure AceInterview receives the audio from your interviews,
            please share a browser tab with audio enabled.
          </p>
          <div className="w-full max-w-3xl">
            <video
              ref={videoRef}
              className="w-full aspect-video bg-slate-200"
              autoPlay
              muted
              playsInline
            />
            <Space className="mt-4 w-full flex items-center justify-center">
              {!isSharing ? (
                <Button
                  onClick={() => {
                    handleStart();
                    onNext(true);
                  }}
                >
                  Connect interview screen 🖥️
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    handleStop();
                    onNext(false);
                  }}
                >
                  Stop Sharing
                </Button>
              )}

              <Button
                loading={launchInterviewLoading}
                disabled={!isSharing}
                onClick={onLaunchInterview}
                type="primary"
              >
                Launch Interview 🚀
              </Button>
            </Space>
          </div>
        </div>
      </Card>
      <div className="mt-6 mb-4 text-lg font-bold">FAQ</div>
      <Collapse accordion items={items} />
    </Card>
  );
};

export default Next3;
