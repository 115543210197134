import { useState, useRef, useCallback } from "react";
import { AudioStatusEnum } from "../../InterviewPage/types";

const useScreenSharing = () => {
  const [isSharing, setIsSharing] = useState<boolean>(false); // 共享状态
  const [screenStream, setScreenStream] = useState<MediaStream | null>(null); // 屏幕流
  const [audioStream, setAudioStream] = useState<MediaStream | null>(null); // 麦克风音频流
  const [combinedAudioStream, setCombinedAudioStream] = useState<MediaStream | null>(null); // 合并后的音频流
  const videoRef = useRef<HTMLVideoElement>(null);

  const [screenAudioStatus, setScreenAudioStatus] = useState<AudioStatusEnum>(
    AudioStatusEnum.UNAVAILABLE
  ); // 屏幕音频状态
  const [microphoneStatus, setMicrophoneStatus] = useState<AudioStatusEnum>(
    AudioStatusEnum.UNAVAILABLE
  ); // 麦克风状态

  // 停止屏幕共享和音频流
  const handleStop = useCallback(() => {
    setIsSharing(false);
    console.log("停止屏幕共享和音频流", screenStream, audioStream);
    if (screenStream) {
      screenStream.getTracks().forEach((track) => track.stop());
    }

    if (audioStream) {
      audioStream.getTracks().forEach((track) => track.stop());
    }

    setScreenStream(null);
    setAudioStream(null);
    setCombinedAudioStream(null);
    setScreenAudioStatus(AudioStatusEnum.UNAVAILABLE);
    setMicrophoneStatus(AudioStatusEnum.UNAVAILABLE);
  }, [screenStream, audioStream]);

  // 开始屏幕共享
  const handleStart = useCallback(async () => {
    try {
      // 获取屏幕流（包含视频和音频）
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: true,
      });

      // 监听屏幕流的结束事件
      screenStream.getVideoTracks()[0].onended = handleStop;
      
      // 获取麦克风音频流
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      // 更新状态
      setScreenStream(screenStream);
      setAudioStream(audioStream);

      if (videoRef.current) {
        videoRef.current.srcObject = screenStream;
      }

      const screenAudioTracks = screenStream.getAudioTracks();
      const micAudioTracks = audioStream.getAudioTracks();

      // 设置音频状态
      setScreenAudioStatus(
        screenAudioTracks.length > 0
          ? AudioStatusEnum.AVAILABLE
          : AudioStatusEnum.UNAVAILABLE
      );
      setMicrophoneStatus(
        micAudioTracks.length > 0
          ? AudioStatusEnum.AVAILABLE
          : AudioStatusEnum.UNAVAILABLE
      );

      // 合并音频流
      const audioContext = new AudioContext();
      const micSource = audioContext.createMediaStreamSource(audioStream);

      if (screenAudioTracks.length > 0) {
        const screenAudioSource = audioContext.createMediaStreamSource(
          new MediaStream(screenAudioTracks)
        );
        const channelMerger = audioContext.createChannelMerger(2);

        micSource.connect(channelMerger, 0, 0); // 麦克风连接到第一声道
        screenAudioSource.connect(channelMerger, 0, 1); // 屏幕音频连接到第二声道

        const combinedAudioDestination =
          audioContext.createMediaStreamDestination();
        channelMerger.connect(combinedAudioDestination);
        setCombinedAudioStream(combinedAudioDestination.stream);
      } else {
        // 如果没有屏幕音频，直接使用麦克风流
        const combinedAudioDestination =
          audioContext.createMediaStreamDestination();
        micSource.connect(combinedAudioDestination);
        setCombinedAudioStream(combinedAudioDestination.stream);
      }

      // 监听麦克风音频流的结束事件
      audioStream.getTracks().forEach((track) => {
        track.onended = handleStop; // 如果麦克风流停止，触发handleStop
      });

      // 监听流的停止事件
      screenStream.oninactive = () => {
        handleStop()
        audioStream.getTracks().forEach((track) => track.stop());
      }
      audioStream.oninactive = handleStop;

      setIsSharing(true);
    } catch (error) {
      console.error("获取屏幕或麦克风权限失败:", error);
      setScreenAudioStatus(AudioStatusEnum.ERROR);
      setMicrophoneStatus(AudioStatusEnum.ERROR);
    }
  }, [handleStop]);

  return {
    screenStream,
    audioStream,
    combinedAudioStream, // 返回合并后的音频流
    isSharing,
    handleStart,
    handleStop,
    videoRef,
    screenAudioStatus,
    microphoneStatus,
  };
};

export default useScreenSharing;
