import { useRef } from "react";

// 工具函数：将 Blob 转换为 base64
const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

const obj = {
  number:0
}

export type DataQueueType = {
  type: string; data: string; sourceType: string; sequenceNumber: number
}

// sendMediaData 和 processQueue 封装
const mediaDataHandler = (
  socketRef: React.RefObject<WebSocket>, 
  dataQueueRef: React.RefObject<DataQueueType[]>,
) => {
  const dataQueue: DataQueueType[] = dataQueueRef.current || [];
  
  let isSending = false;

  const processQueue = () => {
    if (isSending || dataQueue.length === 0) {
      return;
    }

    // 检查 WebSocket 是否连接
    if (socketRef.current?.readyState !== WebSocket.OPEN) {
      setTimeout(processQueue, 1000); // 每隔1秒检查一次 WebSocket 状态
      return;
    }

    // console.log("Processing queue", JSON.parse(JSON.stringify(dataQueue)));
    isSending = true; // 标记为正在发送

    const dataToSend = dataQueue.shift();
    if (dataToSend) {
      try {

        // 发送数据到 WebSocket
        socketRef.current?.send(JSON.stringify(dataToSend));
        // 设置延迟，等待数据完全发送完毕
        setTimeout(() => {
          isSending = false; // 重置状态，准备发送下一个
          processQueue(); // 继续处理队列中的下一个数据
        }, 200); // 50毫秒的延迟可以根据情况调整
      } catch (error) {
        console.error("Failed to send data:", error);
        dataQueue.unshift(dataToSend); // 发送失败，重新入队
        isSending = false; // 重置状态
        setTimeout(processQueue, 200); // 稍后重试
      }
    }
  };

  const sendMediaData = async (
    type: "video" | "audio" | "image" | "text",
    data: string,
    sourceType: "remote" | "local" | "combined"
  ) => {
    const dataToSend = { type, data, sourceType, sequenceNumber: obj.number++,
      time: new Date().toLocaleTimeString()
     };
    dataQueue.push(dataToSend);
    processQueue(); // 处理队列中的数据
  };

  return { sendMediaData, processQueue };
};


// useMediaRecorder Hook
const useMediaRecorder = (
  sendMediaData: (
    type: "video" | "audio" | "image" | "text",
    data: string,
    sourceType: "remote" | "local" | "combined"
  ) => void,
) => {
  const mediaRecorderVideoRef = useRef<MediaRecorder | null>(null);
  const mediaRecorderAudioRef = useRef<MediaRecorder | null>(null);

  const startRecording = (
    videoTracks: MediaStreamTrack[],
    audioTracks: MediaStreamTrack[],
  ) => {
    console.log("视频被重新开始录制");
    const videoRecorder = new MediaRecorder(new MediaStream(videoTracks), {
      mimeType: "video/webm; codecs=vp9",
    });
    const audioRecorder = new MediaRecorder(new MediaStream(audioTracks), {
      mimeType: "audio/webm; codecs=opus",
    });

    mediaRecorderVideoRef.current = videoRecorder;
    mediaRecorderAudioRef.current = audioRecorder;

    // 视频数据处理
    // videoRecorder.ondataavailable = async (event) => {
    //   if (event.data.size > 0) {
    //     const base64String = await blobToBase64(event.data);
    //     sendMediaData("video", base64String, "remote");
    //   }
    // };

    // 音频数据处理
    audioRecorder.ondataavailable = async (event) => {
      if (event.data.size > 0) {
        const base64String = await blobToBase64(event.data);
        sendMediaData("audio", base64String, "combined");
      }
    };

    videoRecorder.start(100); // 每100ms触发一次数据
    audioRecorder.start(300); // 每300ms触发一次数据
  };

  const stopRecording = () => {
    mediaRecorderVideoRef.current?.stop();
    mediaRecorderAudioRef.current?.stop();
  };

  return { startRecording, stopRecording };
};

export { mediaDataHandler, useMediaRecorder };
