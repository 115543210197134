/* eslint-disable @typescript-eslint/no-explicit-any */
import { CopyOutlined, RedoOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Descriptions,
  DescriptionsProps,
  Space,
  Switch,
} from "antd";
import { ReactNode, useEffect, useRef, useState } from "react";
import MarkdownRenderer from "../../components/MarkdownRenderer";
import { useAuth } from "../../contexts/AuthContext";
import ScreenShare from "../../components/ScreenShare";
import {
  getInterviewById,
  Interview,
  InterviewStatus,
} from "../../api/interviews";
import { useParams, useNavigate } from "react-router-dom";
import InterviewInquiryModal from "../../components/InterviewInquiryModal";
import useDebounce from "../../hooks/useDebounce";
import useTimeout from "../../hooks/useTimeout";
import { AudioStatusEnum, SocketStatusEnum, StateType } from "./types";
import styles from "./index.module.scss";
type Message = {
  id: number;
  text: string;
  speaker_id?: string;
  start?: number;
  role?: string;
  speech_final?: boolean;
};

const InterviewPage = () => {
  const { session } = useAuth();
  const [transcriptMessagesMap, setTranscriptMessagesMap] = useState<
    Map<number, Message>
  >(new Map());
  const [copilotMessagesMap, setCopilotMessagesMap] = useState<
    Map<number, Message>
  >(new Map());
  const socketRef = useRef<WebSocket | null>(null);
  const transcriptEndRef = useRef<HTMLDivElement | null>(null);
  const copilotEndRef = useRef<HTMLDivElement | null>(null);
  const [autoScroll, setAutoScroll] = useState<boolean>(true);
  const { interviewId } = useParams<{ interviewId: string }>();
  const navigate = useNavigate();
  const [interview, setInterview] = useState<Interview | null>(null);
  const [renderStatus, setRenderStatus] = useState<ReactNode>();

  const [socketStatus, setSocketStatus] = useState<SocketStatusEnum>();
  const [isInquiryModalOpen, setIsInquiryModalOpen] = useState(false);

  const [microphoneStatus, setMicrophoneStatus] = useState<AudioStatusEnum>();
  const [screenAudioStatus, setScreenAudioStatus] = useState<AudioStatusEnum>();
  const hCss = useRef<string | null>(null);
  const getStatus = ({
    renderStatus,
    socketStatus,
    microphoneStatus,
    screenAudioStatus,
  }: StateType) => {
    setRenderStatus(renderStatus);
    setSocketStatus(socketStatus);
    setMicrophoneStatus(microphoneStatus);
    setScreenAudioStatus(screenAudioStatus);
  };

  const fetchCss = () => {
    import("highlight.js/styles/github.css").then((module) => {
      hCss.current = module.default || module;
      console.log("highlight.js css loaded", hCss.current);
    });
  };

  const handleWebSocketMessage = (event: MessageEvent) => {
    const data = JSON.parse(event.data);

    // Change role to "me" if the role is "interviewee"
    if (data.role === "interviewee") {
      data.role = "me";
    }

    console.log("message", data);

    const updateMessageMap = (
      prevMap: Map<number, Message>,
      newMessage: Message
    ): Map<number, Message> => {
      const updatedMap = new Map(prevMap);
      const existingMessage = updatedMap.get(newMessage.id);

      if (existingMessage) {
        if (newMessage.role === "ai" && existingMessage.speech_final) {
          // For copilot messages, if the existing message is final and we receive a new one,
          // start a new message with the new text
          updatedMap.set(newMessage.id, {
            ...newMessage,
            text: newMessage.text,
            speech_final: false,
          });
        } else {
          // For other cases, append the text
          updatedMap.set(newMessage.id, {
            ...existingMessage,
            text: existingMessage.text + newMessage.text,
            speech_final: newMessage.speech_final,
          });
        }
      } else {
        updatedMap.set(newMessage.id, newMessage);
      }

      return updatedMap;
    };

    if (data.role === "ai") {
      console.log("ai message", data);
      // Handle copilot message
      setCopilotMessagesMap((prevMap) => updateMessageMap(prevMap, data));
    } else {
      // Handle transcript message
      setTranscriptMessagesMap((prevMap) => updateMessageMap(prevMap, data));
    }
  };

  const handleInquiryOk = () => {
    setIsInquiryModalOpen(false);
  };

  const handleStopSharing = () => {
    if (socketRef.current) {
      socketRef.current.close();
    }
  };

  const renderMessage = (msg: Message, index: number) => {
    const isMe = msg.role === "me";

    return (
      <div
        key={`${msg.start}-${index}`}
        className={`flex ${isMe ? "justify-end" : "justify-start"} mb-4`}
      >
        <div className="flex flex-col items-start">
          <div className="text-xs text-gray-500 mb-1">
            {msg.role || msg.speaker_id}
          </div>
          <div
            className={`max-w-2xl p-4 rounded-lg ${
              isMe ? "bg-orange-200 text-black" : "bg-gray-200 text-black"
            }`}
          >
            {msg.text}
          </div>
        </div>
      </div>
    );
  };

  const scrollToBottom = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom(transcriptEndRef);
  }, [transcriptMessagesMap]);

  useEffect(() => {
    if (autoScroll) {
      scrollToBottom(copilotEndRef);
    }
  }, [copilotMessagesMap, autoScroll]);

  useEffect(() => {
    const fetchInterview = async () => {
      if (interviewId) {
        try {
          const interviewData = await getInterviewById(interviewId);
          if (session?.user?.id !== interviewData?.user?.id) {
            // TODO: session doesn't load immediately causing valid page 404
            console.log("interviewData", interviewData);
            console.log("session", session);
            console.log("session?.user?.id", session?.user?.id);
            console.log("interviewData.user_id", interviewData?.user_id);
          }
          if (
            !interviewData ||
            interviewData.status === InterviewStatus.FINISHED
          ) {
            navigate("/404");
          } else {
            setInterview(interviewData);
          }
        } catch (error) {
          console.error("Failed to fetch interview:", error);
          navigate("/404");
        }
      }
    };

    fetchInterview();
  }, [interviewId, navigate]);

  useEffect(() => {
    const hours = 1;
    const minutes = 0;
    const totalMilliseconds = hours * 60 * 60 * 1000 + minutes * 60 * 1000;
    setTimeout(() => {
      window.location.reload();
    }, totalMilliseconds);
  }, []);

  useEffect(() => {
    fetchCss();
  }, []);

  // 等待用户30秒 用户还没确定就关闭链接
  // useTimeout(
  //   () => {
  //     if (isInquiryModalOpen) {
  //       window.location.reload();
  //     }
  //   },
  //   isInquiryModalOpen ? 30 * 1000 : null
  // );

  // 如果后端没有返回数据，则弹窗询问用户是否等待
  // useDebounce(
  //   () => {
  //     if (socketStatus === SocketStatusEnum.OPEN) {
  //       setIsInquiryModalOpen(true);
  //     }
  //   },
  //   60 * 1000,
  //   [copilotMessagesMap, transcriptMessagesMap, socketStatus]
  // );

  const descriptionItems: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Position",
      children: interview?.goals?.position,
      span: 1,
    },
    {
      key: "2",
      label: "Company",
      children: interview?.goals?.company,
      span: 1,
    },
    {
      key: "3",
      label: "Interview Type",
      children: interview?.interview_type,
    },
    {
      key: "4",
      label: "Coding Language",
      children: interview?.coding_language,
    },
    {
      key: "5",
      label: "Microphone",
      children: (
        <Space>
          {microphoneStatus}

          <Badge
            status={
              microphoneStatus === AudioStatusEnum.AVAILABLE
                ? "success"
                : "warning"
            }
          />
        </Space>
      ),
    },
    {
      key: "6",
      label: "Screen Audio",
      children: (
        <Space>
          {screenAudioStatus}

          <Badge
            status={
              screenAudioStatus === AudioStatusEnum.AVAILABLE
                ? "success"
                : "warning"
            }
          />
        </Space>
      ),
    },
    {
      key: "7",
      label: "Socket status",
      children: (
        <Space>
          {socketStatus}
          {renderStatus}
        </Space>
      ),
    },
  ];

  return (
    <div className=" bg-gray-100 ">
      <header className="p-6 bg-white shadow-md rounded-md">
        <Descriptions
          size="small"
          column={4}
          title={
            <span className="text-2xl font-bold text-gray-700">
              Full Stack Software Engineer Interview
            </span>
          }
          // bordered
          items={descriptionItems}
        />
      </header>
      <div className="flex gap-4 w-full mt-4 mt-4">
        <div className="w-2/5 flex flex-col gap-4">
          <Card
            title={<div>Interview </div>}
            className={` shadow-md flex-1 w-full flex flex-col gap-4 ${styles["custom-card-left"]}`}
          >
            <div className="w-full">
              <ScreenShare
                onStop={handleStopSharing}
                onMessage={handleWebSocketMessage}
                interview={interview}
                getStatus={getStatus}
              />
            </div>
          </Card>

          <Card
            className="flex-1 shadow-md"
            title={
              <div className="flex justify-between items-center">
                <h1>Transcription from your interviewer</h1>
                <div className="text-gray-500 text-sm rounded-2xl border border-gray-100 px-2 py-1">
                  <span className="bg-red-500 min-w-2 min-h-2"></span>
                  Transcribing {renderStatus}
                </div>
              </div>
            }
          >
            <div
              className="max-h-screen overflow-auto"
              style={{ height: "calc(100vh - 450px)" }}
            >
              {Array.from(transcriptMessagesMap.values()).map(renderMessage)}
              <div ref={transcriptEndRef} />
            </div>
          </Card>
        </div>

        <Card
          className={`w-3/5 shadow-md `}
          title={
            <div className="flex justify-between items-center">
              <Space>
                <h1>Interview Copilot</h1>
                <div className=" text-sm rounded-2xl border border-gray-100 px-2 py-1">
                  Ready {renderStatus}
                </div>
              </Space>
              <Space className="text-gray-500">
                <Switch
                  checkedChildren="On"
                  defaultChecked
                  unCheckedChildren="Off"
                  onChange={(checked) => setAutoScroll(checked)}
                />
                <Button type="text">Auto Scroll</Button>
              </Space>
            </div>
          }
        >
          <div className="overflow-auto" style={{ height: "100vh" }}>
            {Array.from(copilotMessagesMap.values()).map((item) => (
              <div key={item.id} className="flex gap-6 flex-col mb-6">
                <div className="flex justify-between text-gray-500">
                  <div className="text-xs text-gray-500 mb-1">
                    {item.role || item.speaker_id}
                  </div>
                </div>
                <MarkdownRenderer markdownText={item.text} />
              </div>
            ))}
            <div ref={copilotEndRef} />
          </div>
        </Card>
        {/*         <InterviewInquiryModal
          open={isInquiryModalOpen}
          onCancel={() => {
            setIsInquiryModalOpen(false);
          }}
          onOk={handleInquiryOk}
        /> */}
      </div>
    </div>
  );
};

export default InterviewPage;
