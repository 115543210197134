import React, { useState, useRef, useEffect } from "react";
import {
  Badge,
  Button,
  Popover,
  Space,
  Typography,
} from "antd";
import { useParams } from "react-router-dom";
import {
  PlayCircleOutlined,
  QuestionCircleOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Interview,
  InterviewStatus,
  updateInterviewStatus,
} from "../api/interviews";
import {
  AudioStatusEnum,
  SocketStatusEnum,
  StateType,
} from "../pages/InterviewPage/types";

const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

const { Text } = Typography;

const ScreenShare: React.FC<{
  onStop: () => void;
  onMessage: (event: MessageEvent) => void;
  interview: Interview | null;
  getStatus: (status: StateType) => void;
}> = ({ onStop, onMessage, interview, getStatus }) => {
  const [isSharing, setIsSharing] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [screenshot, setScreenshot] = useState<string | null>(null);
  const [textInput, setTextInput] = useState<string>("");
  const videoRef = useRef<HTMLVideoElement>(null);
  const mediaRecorderVideoRef = useRef<MediaRecorder | null>(null);
  const mediaRecorderAudioRef = useRef<MediaRecorder | null>(null);
  const screenStreamRef = useRef<MediaStream | null>(null);
  const audioStreamRef = useRef<MediaStream | null>(null);
  const socketRef = useRef<WebSocket | null>(null);
  const [screenshotCooldown, setScreenshotCooldown] = useState<number>(0);

  const [socketStatus, setSocketStatus] = useState<SocketStatusEnum>(
    SocketStatusEnum.CLOSED
  );
  const [microphoneStatus, setMicrophoneStatus] = useState<AudioStatusEnum>(
    AudioStatusEnum.UNAVAILABLE
  );
  const [screenAudioStatus, setScreenAudioStatus] = useState<AudioStatusEnum>(
    AudioStatusEnum.UNAVAILABLE
  );
  const [retryCount, setRetryCount] = useState<number>(0);

  const { interviewId } = useParams<{ interviewId: string }>();

  const dataQueue: { type: string; data: string; sourceType: string }[] = [];
  let isSending = false;

  const sendMediaData = (
    type: "video" | "audio" | "image" | "text",
    data: string,
    sourceType: "remote" | "local" | "combined"
  ) => {
    const dataToSend = { type, data, sourceType };
    dataQueue.push(dataToSend);
    processQueue();
  };

  const statusRender = (showText: boolean = true) => {
    let color: string;
    switch (socketStatus) {
      case SocketStatusEnum.CONNECTING:
        color = "processing";
        break;
      case SocketStatusEnum.OPEN:
        color = "success";
        break;
      case SocketStatusEnum.CLOSED:
        color = "warning";
        break;
      case SocketStatusEnum.ERROR:
        color = "error";
        break;
      default:
        color = "default";
    }
    return (
      <Space>
        <Badge status={color as any} />
        {showText ? <Text>{socketStatus}</Text> : <></>}
      </Space>
    );
  };

  const processQueue = () => {
    if (
      isSending ||
      dataQueue.length === 0 ||
      socketRef.current?.readyState !== WebSocket.OPEN
    ) {
      return;
    }

    isSending = true;
    const dataToSend = dataQueue.shift();
    if (dataToSend) {
      try {
        socketRef.current?.send(JSON.stringify(dataToSend));
      } catch (error) {
        console.error("Failed to send data:", error);
        // Requeue the data if sending fails
        dataQueue.unshift(dataToSend);
      }
    }
    isSending = false;

    // Process the next item in the queue
    setTimeout(processQueue, 0);
  };

  const handleStart = async () => {
    console.log("handleStart called");
    try {
      console.log("Attempting to get screen media with audio...");
      const screenStream = await navigator.mediaDevices
        .getDisplayMedia({
          video: {
            displaySurface: "browser",
          },
          audio: true,
        })
        .catch(() =>
          navigator.mediaDevices.getDisplayMedia({ video: true, audio: true })
        );
      console.log("Screen media obtained successfully");

      console.log("Attempting to get microphone media...");
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      console.log("Microphone media obtained successfully");

      screenStreamRef.current = screenStream;
      audioStreamRef.current = audioStream;

      // Set the video element's srcObject to the screen stream
      if (videoRef.current) {
        videoRef.current.srcObject = screenStream;
      }

      const videoTracks = screenStream.getVideoTracks();
      const screenAudioTracks = screenStream.getAudioTracks();
      const micAudioTracks = audioStream.getAudioTracks();

      console.log("Video tracks:", videoTracks.length);
      console.log("Screen audio tracks:", screenAudioTracks.length);
      console.log("Microphone audio tracks:", micAudioTracks.length);

      // Update audio statuses
      setScreenAudioStatus(
        screenAudioTracks.length > 0
          ? AudioStatusEnum.AVAILABLE
          : AudioStatusEnum.UNAVAILABLE
      );
      setMicrophoneStatus(
        micAudioTracks.length > 0
          ? AudioStatusEnum.AVAILABLE
          : AudioStatusEnum.UNAVAILABLE
      );

      if (screenAudioTracks.length === 0) {
        console.warn(
          "No audio tracks found in screen stream. Screen audio might not be captured."
        );
      }

      if (micAudioTracks.length === 0) {
        console.error("No audio tracks found in microphone stream.");
        throw new Error("Failed to get audio tracks from microphone.");
      }

      const audioContext = new AudioContext();
      const micSource = audioContext.createMediaStreamSource(audioStream);
      let combinedAudioStream;

      if (screenAudioTracks.length > 0) {
        const screenAudioSource = audioContext.createMediaStreamSource(
          new MediaStream(screenAudioTracks)
        );
        const channelMerger = audioContext.createChannelMerger(2);
        micSource.connect(channelMerger, 0, 0);
        screenAudioSource.connect(channelMerger, 0, 1);
        combinedAudioStream = audioContext.createMediaStreamDestination();
        channelMerger.connect(combinedAudioStream);
      } else {
        combinedAudioStream = audioContext.createMediaStreamDestination();
        micSource.connect(combinedAudioStream);
      }

      const videoRecorder = new MediaRecorder(new MediaStream(videoTracks), {
        mimeType: "video/webm; codecs=vp9",
      });
      const combinedAudioRecorder = new MediaRecorder(
        combinedAudioStream.stream,
        {
          mimeType: "audio/webm; codecs=opus",
        }
      );

      mediaRecorderVideoRef.current = videoRecorder;
      mediaRecorderAudioRef.current = combinedAudioRecorder;
      videoRecorder.ondataavailable = async (event) => {
        if (event.data.size > 0) {
          //console.log("Video data available:", event.data);
          //const base64String = await blobToBase64(event.data);
          // sendMediaData("video", base64String, "remote");
        }
      };

      combinedAudioRecorder.ondataavailable = async (event) => {
        if (event.data.size > 0) {
          const base64String = await blobToBase64(event.data);
          console.log("Audio data available:");
          sendMediaData("audio", base64String, "combined");
        }
      };

      setIsSharing(true);

      if (interview && interview.status === InterviewStatus.NOT_STARTED) {
        await updateInterviewStatus(interview.id, InterviewStatus.INTERVIEWING);
      }

      try {
        console.log("Starting video recorder");
        videoRecorder.start(100);
        console.log("Starting audio recorder");
        combinedAudioRecorder.start(300);
      } catch (error) {
        console.error("Failed to start MediaRecorder:", error);
        setErrorMsg("Failed to start MediaRecorder: " + error.message);
      }

      screenStream.oninactive = () => {
        handleStop();
      };

      audioStream.oninactive = () => {
        handleStop();
      };
    } catch (err) {
      console.error(
        "Failed to get screen share or microphone permission:",
        err
      );
      setErrorMsg(
        "Failed to get screen share or microphone permission: " + err.message
      );
      setScreenAudioStatus(AudioStatusEnum.ERROR);
      setMicrophoneStatus(AudioStatusEnum.ERROR);
    }
  };

  const handleStop = async () => {
    console.log("handleStop called, setting isSharing to false");
    setIsSharing(false);
    if (mediaRecorderVideoRef.current) {
      mediaRecorderVideoRef.current.stop();
    }
    if (mediaRecorderAudioRef.current) {
      mediaRecorderAudioRef.current.stop();
    }

    if (screenStreamRef.current) {
      screenStreamRef.current.getTracks().forEach((track) => track.stop());
    }
    if (audioStreamRef.current) {
      audioStreamRef.current.getTracks().forEach((track) => track.stop());
    }

    if (socketRef.current) {
      socketRef.current.close();
    }

    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }

    // Reset audio statuses
    setMicrophoneStatus(AudioStatusEnum.UNAVAILABLE);
    setScreenAudioStatus(AudioStatusEnum.UNAVAILABLE);

    setErrorMsg("");
    setRetryCount(0);
    onStop();
  };

  const initSocket = (enableMultichannel: boolean) => {
    console.log("Initializing socket, isSharing:", isSharing);
    setSocketStatus(SocketStatusEnum.CONNECTING);

    if (socketRef.current) {
      socketRef.current.close();
    }
    const url =
      import.meta.env.MODE === "development"
        ? "ws://localhost:8000"
        : "wss://aceinterview-backend.onrender.com";
    socketRef.current = new WebSocket(
      `${url}/listen/${interviewId}?multichannel=${enableMultichannel}`
    );

    socketRef.current.onopen = () => {
      setSocketStatus(SocketStatusEnum.OPEN);
      console.log("WebSocket open");
      processQueue();
    };
    socketRef.current.onmessage = onMessage;
    socketRef.current.onclose = () => {
      setSocketStatus(SocketStatusEnum.CLOSED);
      console.log("WebSocket close");
      // Use a callback to get the latest state
      setIsSharing((currentIsSharing) => {
        console.log("isSharing:", currentIsSharing);
        if (currentIsSharing) {
          retryConnection();
        }
        return currentIsSharing;
      });
    };
    socketRef.current.onerror = (error) => {
      setSocketStatus(SocketStatusEnum.ERROR);
      console.error("WebSocket error:", error);
      // Use a callback to get the latest state
      setIsSharing((currentIsSharing) => {
        if (currentIsSharing) {
          retryConnection();
        }
        return currentIsSharing;
      });
    };
  };

  const retryConnection = () => {
    setRetryCount((prevCount) => {
      const newCount = prevCount + 1;
      console.log(`Retry attempt ${newCount}`);

      if (newCount <= 10) {
        console.log(`Scheduling next retry in 3 seconds...`);
        setTimeout(
          () => initSocket(screenAudioStatus === AudioStatusEnum.AVAILABLE),
          3000
        );
      } else {
        console.log(`Max retry attempts (10) reached. Stopping retries.`);
        // Optionally, you can add some user feedback here
        // e.g., setErrorMsg("Failed to reconnect after multiple attempts.");
      }

      return newCount;
    });
  };

  const takeScreenshot = () => {
    if (videoRef.current) {
      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        const dataUrl = canvas.toDataURL("image/png");
        setScreenshot(dataUrl);
        sendMediaData("image", dataUrl, "local");

        // Start the cooldown
        setScreenshotCooldown(60);
      }
    }
  };

  useEffect(() => {
    let timer: number;
    if (screenshotCooldown > 0) {
      timer = window.setInterval(() => {
        setScreenshotCooldown((prev) => prev - 1);
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [screenshotCooldown]);

  const handleSendText = () => {
    if (textInput.trim()) {
      sendMediaData("text", textInput, "local");
      setTextInput("");
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleStop);
    return () => {
      window.removeEventListener("beforeunload", handleStop);
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (isSharing) {
      console.log("isSharing changed to true, initializing socket");
      initSocket(screenAudioStatus === AudioStatusEnum.AVAILABLE);
    }
  }, [isSharing]);

  useEffect(() => {
    getStatus({
      renderStatus: statusRender(false),
      socketStatus: socketStatus,
      microphoneStatus,
      screenAudioStatus,
    });
  }, [socketStatus, microphoneStatus, screenAudioStatus]);
  return (
    <div className="flex flex-col gap-4" style={{ height: "100%" }}>
      <video
        ref={videoRef}
        className="w-full h-52 bg-slate-200"
        autoPlay
        muted
      />
      <div className="flex justify-around items-center">
        <Button
          onClick={isSharing ? handleStop : handleStart}
          type={isSharing ? "default" : "primary"}
          icon={isSharing ? <StopOutlined /> : <PlayCircleOutlined />}
          size="large"
        >
          {isSharing ? "Stop Sharing" : "Start Sharing"}
        </Button>

        <Space>
          <Button
            onClick={takeScreenshot}
            disabled={!isSharing || screenshotCooldown > 0}
          >
            {screenshotCooldown > 0
              ? `Screenshot (${screenshotCooldown}s)`
              : "Take Screenshot"}
          </Button>
          <Popover content="Send screenshot to AI assistant.">
            <QuestionCircleOutlined />
          </Popover>
        </Space>
      </div>
      {/* <div className="flex overflow-hidden gap-4">
        <div className="flex-1 flex flex-col justify-between items-center gap-4">
          <Input.TextArea
            rows={4}
            value={textInput}
            onChange={(e) => setTextInput(e.target.value)}
            placeholder="Type your message here..."
            disabled={!isSharing}
          />
          <Button
            onClick={handleSendText}
            disabled={!isSharing || !textInput.trim()}
          >
            Send Text
          </Button>
        </div>
      </div> */}
      <Space>
        <div style={{ color: "red" }}>{errorMsg}</div>
        <div className="flex-grow ml-4" style={{ width: "50%" }}>
          {screenshot && (
            <div>
              <h3>Screenshot:</h3>
              <img
                src={screenshot}
                alt="Screenshot"
                style={{ width: "100%" }}
              />
            </div>
          )}
        </div>
      </Space>
    </div>
  );
};

export default ScreenShare;
