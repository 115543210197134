/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Card, message, Modal, Space, Switch } from "antd";
import { ExclamationCircleOutlined, DownOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import useWebSocket from "../hooks/useWebSocket";
import {
  useMediaRecorder,
  mediaDataHandler,
  DataQueueType,
} from "../hooks/useMediaRecorder";
import useScreenshot from "../hooks/useScreenshot";
import MarkdownRenderer from "../../../components/MarkdownRenderer";
import { SocketStatusEnum } from "../../InterviewPage/types";
import { formatTime } from "../../../utils";
import { useNavigate } from "react-router-dom";

type Message = {
  id: number;
  text: string;
  speaker_id?: string;
  start?: number;
  role?: string;
  speech_final?: boolean;
};

type Props = {
  screenShareParams: any;
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
};

const ChatFrame = ({
  screenShareParams,
  isModalOpen,
  handleOk,
  handleCancel,
}: Props) => {
  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [copilotMessagesMap, setCopilotMessagesMap] = useState<
    Map<number, Message>
  >(new Map());
  const [transcriptMessagesMap, setTranscriptMessagesMap] = useState<
    Map<number, Message>
  >(new Map());
  const copilotEndRef = useRef<HTMLDivElement | null>(null);
  const transcriptEndRef = useRef<HTMLDivElement | null>(null);
  const [autoScroll, setAutoScroll] = useState<boolean>(true);

  const [time, setTime] = useState<number>(0);
  const [screenshot, setScreenshot] = useState<string | null>(null);

  const onMessage = (e: any) => {
    console.log("WebSocket message received:", e);

    const data = JSON.parse(e.data);

    // Change role to "me" if the role is "interviewee"
    if (data.role === "interviewee") {
      data.role = "me";
    }

    const updateMessageMap = (
      prevMap: Map<number, Message>,
      newMessage: Message
    ): Map<number, Message> => {
      const updatedMap = new Map(prevMap);
      const existingMessage = updatedMap.get(newMessage.id);

      if (existingMessage) {
        updatedMap.set(newMessage.id, {
          ...existingMessage,
          text: existingMessage.text + newMessage.text,
          speech_final: newMessage.speech_final,
        });
      } else {
        updatedMap.set(newMessage.id, newMessage);
      }

      return updatedMap;
    };
    if (data.role === "ai") {
      console.log("Copilot message:", data);
      // Handle copilot message
      setCopilotMessagesMap((prevMap) => updateMessageMap(prevMap, data));
    } else {
      // Handle transcript message
      setTranscriptMessagesMap((prevMap) => updateMessageMap(prevMap, data));
    }
  };

  const dataQueueRef = useRef<DataQueueType[]>([]);

  const { socketStatus, initSocket, socketRef, closeSocket } = useWebSocket(
    screenShareParams?.interview?.id,
    onMessage,
    screenShareParams.isSharing
  );

  const { sendMediaData, processQueue } = mediaDataHandler(
    socketRef,
    dataQueueRef
  );
  const { startRecording, stopRecording } = useMediaRecorder(sendMediaData);

  const { screenshot: screenshotHook, takeScreenshot } = useScreenshot(
    videoRef
  );

  const handleEndInterview = () => {
    Modal.confirm({
      title: "Are you sure you want to end the interview?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone.",
      onOk() {
        handleStop();
        handleCancel(); // Close the ChatFrame modal
        setTimeout(() => {
          navigate(`/feedback/${screenShareParams?.interview?.id}`);
        }, 300);
      },
      onCancel() {
        // Do nothing if the user cancels
      },
    });
  };

  const handleStop = () => {
    closeSocket(() => {
      stopRecording();
      screenShareParams?.handleStop();
      setTime(0);
    });
  };

  useEffect(() => {
    if (!screenShareParams?.isSharing) {
      handleStop();
      handleCancel();
    }
  }, [screenShareParams?.isSharing]);

  useEffect(() => {
    if (!isModalOpen) return;

    if (videoRef.current && screenShareParams) {
      videoRef.current.srcObject = screenShareParams?.videoStream;
    }

    initSocket(true, processQueue);
  }, [videoRef, screenShareParams, isModalOpen]);

  useEffect(() => {
    if (!screenShareParams) return;
    if (time > 0) {
      //首次不清空  后面断开后每次都清空
      stopRecording();

      dataQueueRef.current.splice(0, dataQueueRef.current.length);
      console.log("清空后的dataQueue", dataQueueRef.current);
    }
    if (socketStatus === SocketStatusEnum.OPEN && screenShareParams.isSharing) {
      setTime((prevTime) => prevTime + 1000);
      startRecording(
        screenShareParams?.videoStream?.getVideoTracks(),
        screenShareParams?.combinedAudioStream?.getAudioTracks()
      );
    }
  }, [screenShareParams, socketStatus]);

  const scrollToBottom = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (time === 0) return;
    const timer = setTimeout(() => {
      setTime((prevTime) => prevTime + 1000);
    }, 1000);

    return () => clearTimeout(timer);
  }, [time]);

  useEffect(() => {
    if (autoScroll) {
      scrollToBottom(copilotEndRef);
    }
  }, [copilotMessagesMap, autoScroll]);

  useEffect(() => {
    scrollToBottom(transcriptEndRef);
  }, [transcriptMessagesMap]);

  const renderMessage = (msg: Message, index: number) => {
    const isUser = msg.role === "me";
    const messageStyles = isUser
      ? "bg-orange-200 text-black self-end"
      : "bg-gray-200 text-black self-start";

    return (
      <div key={`${msg.start}-${index}`} className="mb-4 flex">
        <div className="flex flex-col items-start">
          <span className="text-xs text-gray-500 mb-1">
            {msg.role || msg.speaker_id}
          </span>
          <div className={`max-w-2xl p-4 rounded-lg ${messageStyles}`}>
            {msg.text}
          </div>
        </div>
      </div>
    );
  };

  const requestScreenshot = () => {
    console.log("ChatFrame: Requesting screenshot");
    const event = new CustomEvent("FromAceInterview", {
      detail: { text: "take_screenshot" },
    });
    window.dispatchEvent(event);
  };
  const handleScreenshotEvent = (event: any) => {
    if (
      event.detail &&
      event.detail.type === "FromExtension" &&
      event.detail.image
    ) {
      console.log("截图成功", event.detail.image);
      sendMediaData("image", event.detail.image, "local");

      message.success("Screenshot sent successfully");
    }
  };

  useEffect(() => {
    console.log("Adding event listener for screenshotEvent");
    window.addEventListener("screenshotCaptured", handleScreenshotEvent);

    // Cleanup listener on component unmount
    return () => {
      console.log("Removing event listener for screenshotEvent");
      window.removeEventListener("screenshotCaptured", handleScreenshotEvent);
    };
  }, []);

  return (
    <Modal
      title="Interview Session"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      width={"100%"}
      closable={false}
      maskClosable={false}
      mask={false}
      keyboard={false}
      footer={
        <div className="flex justify-between">
          <Space>Time: {formatTime(time)}</Space>
          <div className="flex gap-2">
            <Button
              onClick={handleEndInterview}
              type="primary"
              className="px-4 py-2 rounded-md"
            >
              End
            </Button>
          </div>
        </div>
      }
    >
      <div className="h-[calc(100vh-150px)] w-full flex gap-2" id="xxx">
        <Card className="h-full w-[500px]">
          <div className="flex flex-col gap-2 p-2 h-full">
            <div className="flex flex-col gap-2">
              <div>Interviewer</div>
              <video
                ref={videoRef}
                autoPlay
                muted
                className="h-[200px]"
                width="100%"
              ></video>
              <Button type="primary" onClick={requestScreenshot}>
                Take Screenshot
              </Button>
              {screenshot && (
                <div className="mt-2">
                  <img
                    src={screenshot}
                    alt="Screenshot"
                    className="w-full h-auto"
                  />
                  <Button
                    type="primary"
                    onClick={() => setScreenshot(null)}
                    className="mt-2"
                  >
                    Clear Screenshot
                  </Button>
                </div>
              )}
            </div>
            <div
              className="max-h-screen overflow-auto"
              style={{ height: "calc(95vh - 450px)" }}
            >
              {Array.from(transcriptMessagesMap.values()).map(renderMessage)}
              <div ref={transcriptEndRef} />
            </div>
          </div>
        </Card>

        <Card
          className="w-3/5 shadow-md relative"
          title={
            <div className="flex justify-between items-center">
              <Space>
                <h1>Interview Copilot</h1>
              </Space>
              <Space className="text-gray-200">
                <Switch
                  checkedChildren="On"
                  defaultChecked
                  unCheckedChildren="Off"
                  onChange={(checked) => setAutoScroll(checked)}
                />
                <Button type="text">Auto Scroll</Button>
              </Space>
            </div>
          }
        >
          <div className="max-h-screen overflow-auto h-[calc(100vh-250px)] relative">
            {Array.from(copilotMessagesMap.values())
              .filter((v) => v.text)
              .map((item) => (
                <div key={item.id} className={`mb-4 p-4 bg-gray-100`}>
                  <MarkdownRenderer markdownText={item.text} />
                </div>
              ))}
            <div ref={copilotEndRef} />
          </div>
          <Button
            className="absolute bottom-4 right-4 bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 transition-colors z-10"
            icon={<DownOutlined />}
            onClick={() => scrollToBottom(copilotEndRef)}
          />
        </Card>
      </div>
    </Modal>
  );
};

export default ChatFrame;
