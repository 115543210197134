import { supabase } from "../lib/supabaseClient";

export type UserSettings = {
  language: string;
  verbosity: string;
};

export const updateUserSettings = async (userId: string, settings: UserSettings): Promise<void> => {
  console.log('Upserting user settings:', settings);
  const { error } = await supabase
    .from('profiles')
    .upsert({
      user_id: userId,
      language: settings.language,
      verbosity: settings.verbosity
    }, {
      onConflict: 'user_id'
    });

  if (error) {
    console.error('Error upserting user settings:', error);
    throw error;
  }
};

export const getUserSettings = async (userId: string): Promise<UserSettings | null> => {
  const { data, error } = await supabase
    .from('profiles')
    .select('language, verbosity')
    .eq('user_id', userId)
    .single();

  if (error) {
    if (error.code === 'PGRST116') {
      // User not found
      return null;
    }
    throw error;
  }

  return data;
};
