import React, { useEffect, useState } from "react";
import { Modal, Input, Upload, Switch, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

interface DocumentUploadModalProps {
  open: boolean;
  onClose: () => void;
  onUpload: (
    file: File,
    filename: string,
    documentType: string,
    isActivated: boolean
  ) => void;
  uploading: boolean;
}

const DocumentUploadModal: React.FC<DocumentUploadModalProps> = ({
  open,
  onClose,
  onUpload,
  uploading,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [filename, setFilename] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [isActivated, setIsActivated] = useState(false);

  const [dateTime, setDateTime] = useState(new Date().getTime());

  const resetForm = () => {
    setFile(null);
    setFilename("");
    setDocumentType("");
    setIsActivated(true);
  };

  const handleUpload = () => {
    if (file) {
      onUpload(file, filename, documentType, isActivated);
    }
  };

  const handleBeforeUpload = (file: File) => {
    if (file.type !== 'application/pdf') {
      message.error('Please upload a PDF file.');
      return Upload.LIST_IGNORE;
    }
    if (file.size > 5 * 1024 * 1024) {
      message.error("File must be smaller than 5MB!");
      return Upload.LIST_IGNORE;
    }
    setDocumentType(file.type);
    setFilename(file.name);
    setFile(file);
    return false;
  };

  useEffect(() => {
    if (open) {
      setDateTime(new Date().getTime());
      resetForm();
    }
  }, [open]);
  return (
    <Modal
      width={550}
      open={open}
      title="Upload Document"
      okText="Upload"
      cancelText="Cancel"
      onCancel={onClose}
      onOk={handleUpload}
      confirmLoading={uploading}
    >
      <div className="flex flex-col gap-4">
        <Upload
          key={dateTime}
          name="file"
          maxCount={1}
          beforeUpload={handleBeforeUpload}
          accept=".pdf"
        >
          <Button icon={<UploadOutlined />}>Select PDF File (Max 5MB)</Button>
        </Upload>
        <div>
          Activated
          <Switch checked={isActivated} onChange={setIsActivated} />
        </div>
        <Input
          placeholder="Filename"
          value={filename}
          onChange={(e) => setFilename(e.target.value)}
        />
        <Input
          placeholder="Document Type"
          value={documentType}
          onChange={(e) => setDocumentType(e.target.value)}
        />
      </div>
    </Modal>
  );
};

export default DocumentUploadModal;
