import { ReactNode } from "react";

export enum SocketStatusEnum {
  CONNECTING = "Connecting",
  OPEN = "Connected",
  CLOSING = "Disconnecting",
  CLOSED = "Disconnected",
  ERROR = "Error",
}

export enum AudioStatusEnum {
  UNAVAILABLE = "Unavailable",
  AVAILABLE = "Available",
  ERROR = "Error",
}


export type StateType = {
  renderStatus: ReactNode;
  socketStatus: SocketStatusEnum;
  microphoneStatus: AudioStatusEnum;
  screenAudioStatus: AudioStatusEnum
}