/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Card, Steps, Form } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { SettingOutlined } from "@ant-design/icons";
import Next1 from "./components/Next1";
import Next2 from "./components/Next2";
import Next3 from "./components/Next3";
import { createInterview, InterviewStatus } from "../../api/interviews";
import { useAuth } from "../../contexts/AuthContext";
import {
  updateUserSettings,
  getUserSettings,
  UserSettings,
} from "../../api/users";
import { InterviewSettingsModal } from "./components/InterviewSettingsModal";
import ChatFrame from "./components/ChatFrame";

const stepsItems = [
  {
    title: "Step 1",
    description: "Specify the Job Application",
  },
  {
    title: "Step 2",
    description: "Choose the Interview Type",
  },
  {
    title: "Step 3",
    description: "Connect Meeting",
  },
];

const InterviewsNew = () => {
  const { session } = useAuth();
  const [current, setCurrent] = useState(0);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [userSettings, setUserSettings] = useState<UserSettings | null>(null);
  const [settingsForm] = Form.useForm();

  const paramsRef = useRef<any>({});

  const [loading, setLoading] = useState(false);

  const [disabled, setDisabled] = useState(true);

  const [screenShareParams, setScreenShareParams] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onChange = (value: number) => {
    console.log("onChange:", value);
    if (value > current) return;
    setCurrent(value);
  };

  const handleSettingsModalOpen = async () => {
    if (!session?.user) {
      console.error("No user logged in");
      return;
    }

    try {
      const settings = await getUserSettings(session.user.id);
      setUserSettings(settings);
      setIsSettingsModalOpen(true);
    } catch (error) {
      console.error("Error loading settings:", error);
      // Optionally, show an error message to the user
    }
  };

  const handleSettingsModalClose = () => {
    setIsSettingsModalOpen(false);
  };

  const handleSettingsSave = async (values: UserSettings) => {
    if (!session?.user) {
      console.error("No user logged in");
      return;
    }

    try {
      await updateUserSettings(session.user.id, values);
      setUserSettings(values);
      setIsSettingsModalOpen(false);
      console.log("Settings saved:", values);
    } catch (error) {
      console.error("Error saving settings:", error);
      // Optionally, show an error message to the user
    }
  };

  const handleLaunchInterview = async () => {
    if (!session) return;
    try {
      setLoading(true);

      const settings = await getUserSettings(session.user.id);
      const interviewData = {
        user_id: session.user.id,
        interview_type: paramsRef.current.interviewType.key,
        coding_language: null,
        goal_id: paramsRef.current.goal.id,
        settings: {
          language: settings?.language,
          verbosity: settings?.verbosity,
        },
        status: InterviewStatus.NOT_STARTED,
      };

      const res = await createInterview(interviewData);
      console.log(res);
      setIsModalOpen(true);
      setScreenShareParams({
        ...screenShareParams,
        interview: res,
      });
    } catch (error) {
      console.error("Error launching interview:", error);
      // Optionally, show an error message to the user
    } finally {
      setLoading(false);
    }
  };

  const readerNext = (
    <>
      <div className={`h-full ${current === 0 ? "show" : "hidden"}`}>
        <Next1
          onNext={(e: any) => {
            paramsRef.current = {
              goal: e,
            };
            setDisabled(true);

            setCurrent(current + 1);
          }}
        />
      </div>
      <div className={`h-full ${current === 1 ? "show" : "hidden"}`}>
        <Next2
          onNext={(e: any) => {
            setCurrent(current + 1);
            setDisabled(true);
            paramsRef.current = {
              ...paramsRef.current,
              interviewType: e,
            };
          }}
        />
      </div>
      <div className={`h-full ${current === 2 ? "show" : "hidden"}`}>
        <Next3
          onNext={(e: boolean) => {
            setDisabled(!e);
          }}
          onScreenShareParams={(e: any) => {
            console.log("Screen share params:", e);
            setScreenShareParams(e);
          }}
          onLaunchInterview={handleLaunchInterview}
          launchInterviewLoading={loading}
        />
      </div>
    </>
  );

  return (
    <div className="flex gap-4 items-center justify-center w-full h-full">
      <Card className="w-[300px] h-full">
        <div className="mb-8">
          <h1 className="text-xl font-bold ">AI Interview Assistant</h1>
          <p className="text-sm text-gray-500">Ace your live interview today</p>
        </div>
        <Steps
          current={current}
          onChange={onChange}
          direction="vertical"
          items={stepsItems}
        />
        <Button
          icon={<SettingOutlined />}
          onClick={handleSettingsModalOpen}
          className="mt-4 w-full"
        >
          Interview Settings
        </Button>
      </Card>
      <div className="flex-1 w-full h-full"> {readerNext}</div>

      <InterviewSettingsModal
        open={isSettingsModalOpen}
        onCancel={handleSettingsModalClose}
        onOk={handleSettingsSave}
        initialValues={userSettings}
      />
      {isModalOpen && (
        <ChatFrame
          handleCancel={() => {
            setIsModalOpen(false);
          }}
          handleOk={() => {
            setIsModalOpen(false);
          }}
          isModalOpen={isModalOpen}
          screenShareParams={screenShareParams}
        />
      )}
    </div>
  );
};

export default InterviewsNew;
