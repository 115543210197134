import { createBrowserRouter } from "react-router-dom";

import InterviewPage from "../pages/InterviewPage";
import Goals from "../pages/Jobs";
import Documents from "../pages/Documents";
import Dashboard from "../pages/Dashboard";
import Interviews from "../pages/Interviews";
import Auth from "../pages/Auth";
import AntdLayout from "../components/AntdLayout";
import NotFoundPage from "../pages/NotFoundPage";
import ResetPassword from "../pages/ResetPassword";
import InterviewsNew from "../pages/InterviewsNew";
import Feedback from "../pages/Feedback";
import Resume from "../pages/Resume";
import Subscribe from "../pages/Subscribe";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <AntdLayout />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      { path: "/documents", element: <Documents /> },
      { path: "/interview", element: <InterviewsNew /> },
      {
        path: "/jobs",
        element: <Goals />,
      },
      {
        path: "/feedback/:interviewId",
        element: <Feedback />,
      },
      {
        path: "/resume",
        element: <Resume />,
      },
      {
        path: "/subscribe",
        element: <Subscribe />,
      },
    ],
  },
  {
    path: "/interview/:interviewId",
    element: <InterviewPage />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/login",
    element: <Auth />,
  },
  {
    path: "/404",
    element: <NotFoundPage />,
  },
  {
    path: "*",
    element: <Auth />,
  },
]);
