import { useEffect, useState } from "react";
import { supabase } from "../lib/supabaseClient";
import { useAuth } from "../contexts/AuthContext";
import "../styles/Auth.css";
import { useNavigate } from "react-router-dom";
import { Button, Flex, Input, message, Tabs, Modal } from "antd";
import SignUpModal from "../components/SignUpModal";
import ReactGA from "react-ga4";

export default function Auth() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [activeTab, setActiveTab] = useState("1"); // Set default to "1" for Magic Link
  const { session } = useAuth();

  const [signUpModalVisible, setSignUpModalVisible] = useState(false);
  const [resetPasswordModalVisible, setResetPasswordModalVisible] = useState(
    false
  );
  const [resetEmail, setResetEmail] = useState("");

  const items = [
    {
      key: "1",
      label: "Magic Link",
    },
    {
      key: "2",
      label: "Password Login",
    },
  ];

  const handleAuth = async (event) => {
    event.preventDefault();
    setLoading(true);

    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      message.error(error.error_description || error.message);
      ReactGA.event({
        category: "User",
        action: "Login Failed",
        label: error.message,
      });
    } else {
      navigate("/interviews");
      message.success("Logged in successfully!");
      ReactGA.event({
        category: "User",
        action: "Login Successful",
      });
    }
    setLoading(false);
  };

  const signUp = async (email: string, password: string) => {
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
    });

    if (error) {
      console.error("Error signing up:", error.message);
      return null;
    }

    console.log("User signed up successfully:", data);
    return data;
  };

  const handleMagicLink = async (event) => {
    event.preventDefault();
    setLoading(true);

    const redirectTo = window.location.origin;
    console.log(redirectTo);
    const { error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        emailRedirectTo: `${redirectTo}/auth/callback`,
      },
    });

    if (error) {
      message.error(error.error_description || error.message);
      ReactGA.event({
        category: "User",
        action: "Magic Link Failed",
        label: error.message,
      });
      setLoading(false);
    } else {
      message.success("Magic link sent! Check your email to sign in.", 10);
      ReactGA.event({
        category: "User",
        action: "Magic Link Sent",
      });
    }
    setLoading(false);
  };

  const handleResetPassword = async () => {
    setLoading(true);
    const { error } = await supabase.auth.resetPasswordForEmail(resetEmail, {
      redirectTo: `${window.location.origin}/reset-password`,
    });

    if (error) {
      message.error(error.message);
    } else {
      message.success("Password reset email sent. Check your inbox.");
      setResetPasswordModalVisible(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    supabase.auth.onAuthStateChange((_event, session) => {
      if (session) {
        navigate("/interview");
        setLoading(false);
      }
    });
  }, []);

  if (session) {
    return <div>You are already logged in!</div>;
  }

  return (
    <div className="auth-container">
      <div className="auth-right">
        <h1 className="auth-header">Sign in to Ace Interview</h1>
        <p className="auth-subheader">
          Welcome back! Please sign in to continue
        </p>
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={(e) => setActiveTab(e)}
        />
        <form
          className="auth-form"
          onSubmit={activeTab === "2" ? handleAuth : handleMagicLink}
        >
          <Input
            className="auth-input"
            type="email"
            placeholder="Email address"
            value={email}
            required={true}
            onChange={(e) => setEmail(e.target.value)}
          />
          {activeTab === "2" && (
            <>
              <input
                className="auth-input"
                type="password"
                placeholder="Password"
                value={password}
                required={true}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="link"
                onClick={() => setResetPasswordModalVisible(true)}
                style={{ padding: 0, height: "auto", marginBottom: "10px" }}
              >
                Forgot password?
              </Button>
            </>
          )}
          <button className="auth-button" disabled={loading}>
            {loading ? (
              <span>Loading...</span>
            ) : (
              <span>{activeTab === "2" ? "Continue" : "Send Magic Link"}</span>
            )}
          </button>
        </form>
        <SignUpModal
          visible={signUpModalVisible}
          onCancel={() => {
            setSignUpModalVisible(false);
          }}
        />
        <p className="auth-signup">
          Don't have an account?{" "}
          <Button
            type="link"
            onClick={() => {
              setSignUpModalVisible(true);
            }}
          >
            Sign up
          </Button>
        </p>
      </div>

      <Modal
        title="Reset Password"
        open={resetPasswordModalVisible}
        onOk={handleResetPassword}
        onCancel={() => setResetPasswordModalVisible(false)}
        confirmLoading={loading}
      >
        <p>Enter your email address to receive a password reset link:</p>
        <Input
          type="email"
          placeholder="Email address"
          value={resetEmail}
          onChange={(e) => setResetEmail(e.target.value)}
          style={{ marginTop: "10px" }}
        />
      </Modal>
    </div>
  );
}
