import { useState, useRef, useEffect } from "react";

const useScreenshot = (videoRef: React.RefObject<HTMLVideoElement>) => {
  const [screenshot, setScreenshot] = useState<string | null>(null);
  const [screenshotCooldown, setScreenshotCooldown] = useState<number>(0);

  const takeScreenshot = () => {
    if (videoRef.current) {
      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        const dataUrl = canvas.toDataURL("image/png");
        setScreenshot(dataUrl);
        setScreenshotCooldown(60);
      }
    }
  };

  useEffect(() => {
    let timer: number;
    if (screenshotCooldown > 0) {
      timer = window.setInterval(() => {
        setScreenshotCooldown((prev) => prev - 1);
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [screenshotCooldown]);

  return { screenshot, screenshotCooldown, takeScreenshot };
};

export default useScreenshot;
