import React, { useState, useEffect } from "react";
import {
  createDocument,
  listDocumentsByUser,
  deleteDocument,
  updateDocument,
  Document,
} from "../../api/documents";
import { useAuth } from "../../contexts/AuthContext";
import CustomTable from "../../components/CustomTable";
import DocumentUploadModal from "./components/DocumentUploadModal";
import { Button, message, Popconfirm, Switch } from "antd";

const Documents: React.FC = () => {
  const { session } = useAuth();
  const [documents, setDocuments] = useState<Document[]>([]);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (session?.user.id) {
      fetchDocuments(session.user.id);
    }
  }, [session]);

  const fetchDocuments = async (userId: string) => {
    setLoading(true);
    try {
      const { documents } = await listDocumentsByUser(userId);
      setDocuments(documents);
    } catch (error) {
      console.error("Error fetching documents:", error);
      message.error("Failed to fetch documents");
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = async (
    file: File,
    filename: string,
    documentType: string,
    isPrimary: boolean
  ) => {
    if (!session?.user.id) return;

    setUploading(true);
    try {
      await createDocument(
        file,
        filename,
        documentType,
        session.user.id,
        isPrimary
      );
      message.success("Document uploaded successfully");
      setModalOpen(false);
      fetchDocuments(session.user.id);
    } catch (error) {
      console.error("Error uploading document:", error);
      message.error("Failed to upload document");
    } finally {
      setUploading(false);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteDocument(id);
      message.success("Document deleted successfully");
      if (session?.user.id) {
        fetchDocuments(session.user.id);
      }
    } catch (error) {
      console.error("Error deleting document:", error);
      message.error("Failed to delete document");
    }
  };

  const handleToggleActivation = async (id: string, currentStatus: boolean) => {
    try {
      await updateDocument(id, { is_activated: !currentStatus });
      message.success("Document activation status updated successfully");
      if (session?.user.id) {
        fetchDocuments(session.user.id);
      }
    } catch (error) {
      console.error("Error updating document activation:", error);
      message.error("Failed to update document activation status");
    }
  };

  const columns = [
    { title: "Filename", dataIndex: "file_name", key: "filename" },
    { title: "Document Type", dataIndex: "file_type", key: "document_type" },
    {
      title: "Activated",
      dataIndex: "is_activated",
      key: "is_activated",
      render: (isActivated: boolean, record: Document) => (
        <Switch
          checked={isActivated}
          onChange={() => handleToggleActivation(record.id, isActivated)}
        />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record: Document) => (
        <Popconfirm
          title="Delete the document?"
          description="Are you sure you want to delete this document?"
          onConfirm={() => handleDelete(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <Button danger>Delete</Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <div className="flex flex-col gap-2">
          <h1 className="font-bold text-2xl">Resume</h1>
          <p className="text-gray-500">
            Upload resume to train your AI.
          </p>
        </div>
        <Button type="primary" onClick={() => setModalOpen(true)}>
          Upload
        </Button>
      </div>
      <CustomTable columns={columns} dataSource={documents} loading={loading} />
      <DocumentUploadModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onUpload={handleUpload}
        uploading={uploading}
      />
    </>
  );
};

export default Documents;
