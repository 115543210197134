import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col, Skeleton, message } from "antd";
import { IProps } from "../types";
import { createGoal, listGoalsByUser, type Goal as GoalType } from "../../../api/goals";
import { useAuth } from "../../../contexts/AuthContext";
import CreateModal from "../../Jobs/components/CreateModal";

const jobCategories = [
  { value: "software", label: "💻 Software" },
  { value: "data_science", label: "📊 Data Science" },
  { value: "product_management", label: "💡 Product Management" },
  { value: "finance", label: "💰 Finance" },
  { value: "consulting", label: "👥 Consulting" },
  { value: "marketing", label: "📣 Marketing" },
  { value: "sales", label: "💼 Sales" },
  { value: "design", label: "🎨 Design" },
  { value: "customer_support", label: "🎧 Customer Support" },
  { value: "others", label: "🔹 Others" },
];

const getCategoryLabel = (value: string) => {
  const category = jobCategories.find(cat => cat.value === value);
  return category ? category.label : value;
};

const Next1 = ({ onNext, onBack }: IProps) => {
  const { session } = useAuth();
  const [goals, setGoals] = useState<GoalType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchGoals = async () => {
    if (!session) {
      setLoading(false);
      return;
    }
    try {
      const userId = session.user.id;
      const { goals } = await listGoalsByUser(userId);
      setGoals(goals);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGoals();
  }, [session]);

  const handleAddNewJob = () => {
    setIsModalOpen(true);
  };

  const handleModalFinish = async (values: GoalType) => {
    console.log("New position created:", values);
    if (!session) {
      setLoading(false);
      return;
    }
    const userId = session.user.id;
    createGoal({ ...values, user_id: userId }).then(() => {
      setIsModalOpen(false);
      fetchGoals();
    })
    .catch((error) => {
      console.error(error.message);
    });

  };

  // if (loading) return <Skeleton />;
  if (error) return <div>Error: {error}</div>;

  return (
    <Card className="w-full h-full">
      <div className="flex justify-end mb-4">
        <Button type="primary" onClick={handleAddNewJob}>
          Add new application
        </Button>
      </div>

      <Row gutter={[16, 16]}>
      {loading? <Skeleton /> : goals.map((goal) => (
          <Col key={goal.id} xs={24} sm={24} md={12} lg={12} xl={12}>
            <Card 
              hoverable 
              className="h-full cursor-pointer" 
              onClick={() => onNext(goal)}
            >
              <div className="flex flex-col justify-between h-full">
                <div>
                  <div className="font-bold text-lg">{goal.position}</div>
                  <div>{goal.company}</div>
                </div>
                <div className="flex justify-between items-center mt-4">
                  <div>{getCategoryLabel(goal.category)}</div>
                </div>
              </div>
            </Card>
          </Col>
        ))}
      </Row>

      <CreateModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        formValues={{}}
        form={null as any}
        onFinish={handleModalFinish}
        isEditing={false}
      />
    </Card>
  );
};

export default Next1;
