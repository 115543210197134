import { useRef, useState } from "react";
import { SocketStatusEnum } from "../../InterviewPage/types";

const useWebSocket = (
  interviewId: string | undefined,
  onMessage: (event: MessageEvent) => void,
  isSharing: boolean
) => {
  const [socketStatus, setSocketStatus] = useState<SocketStatusEnum>(
    SocketStatusEnum.CLOSED
  );
  const socketRef = useRef<WebSocket | null>(null);
  const retryCount = useRef<number>(0);
  const isManualClose = useRef<boolean>(false); // 标识是否为手动关闭

  const initSocket = (enableMultichannel: boolean, processQueue?: () => void) => {
    if (
      socketRef.current &&
      socketRef.current.readyState !== WebSocket.CLOSED &&
      socketRef.current.readyState !== WebSocket.CLOSING
    ) {
      return; // Socket is already open or connecting
    }
    setSocketStatus(SocketStatusEnum.CONNECTING);
    const url =
      import.meta.env.MODE === "development"
        ? "ws://localhost:8000"
        : "wss://aceinterview-backend.onrender.com";

    socketRef.current = new WebSocket(
      `${url}/listen/${interviewId}?multichannel=${enableMultichannel}`
    );

    window.socket = socketRef.current;

    socketRef.current.onopen = () => {
      setSocketStatus(SocketStatusEnum.OPEN);
      retryCount.current = 0;
      if (processQueue) processQueue(); // 重新连接后处理队列
    };

    socketRef.current.onmessage = onMessage;

    socketRef.current.onclose = () => {
      console.log("socket closed");
      setSocketStatus(SocketStatusEnum.CLOSED);

      // 只有在不是手动关闭时，才重试连接
      if (!isManualClose.current && isSharing) {
        retryConnection(enableMultichannel, processQueue);
      }
    };

    socketRef.current.onerror = () => {
      setSocketStatus(SocketStatusEnum.ERROR);
      if (!isManualClose.current && isSharing) {
        retryConnection(enableMultichannel, processQueue);
      }
    };
  };

  const retryConnection = (enableMultichannel: boolean, processQueue?: () => void) => {
    retryCount.current += 1;
    if (retryCount.current <= 10) {
      setTimeout(() => initSocket(enableMultichannel, processQueue), 3000);
    }
  };

  const closeSocket = (closedCallback?: () => void) => {
    isManualClose.current = true; // 设置为手动关闭
    if (socketRef.current) {
      socketRef.current.close();
      if (closedCallback) closedCallback();
    }
  };

  return { socketStatus, initSocket, closeSocket, socketRef };
};

export default useWebSocket;
