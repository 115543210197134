import { supabase } from "../lib/supabaseClient";
import { Database } from "../database.types";

export type Goal = Database["public"]["Tables"]["goals"]["Row"];
type GoalInsert = Database["public"]["Tables"]["goals"]["Insert"];
type GoalUpdate = Database["public"]["Tables"]["goals"]["Update"];

// Create a new goal
export const createGoal = async (goal: GoalInsert): Promise<Goal> => {
  const { data, error } = await supabase.from("goals").insert(goal).single();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

// Retrieve a goal by ID
export const getGoalById = async (id: string): Promise<Goal> => {
  const { data, error } = await supabase
    .from("goals")
    .select("*")
    .eq("id", id)
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

// List goals by user ID with pagination
export const listGoalsByUser = async (
  userId: string,
  page: number = 1,
  pageSize: number = 10
): Promise<{ goals: Goal[]; total: number }> => {
  const from = (page - 1) * pageSize;
  const to = from + pageSize - 1;

  // Fetch goals with pagination
  const {
    data: goals,
    error,
    count,
  } = await supabase
    .from("goals")
    .select("*", { count: "exact" })
    .eq("user_id", userId)
    .order('created_at', { ascending: false })
    .range(from, to);

  if (error) {
    throw new Error(error.message);
  }

  return { goals: goals as Goal[], total: count || 0 };
};

// Count goals by user ID
export const countGoalsByUser = async (userId: string): Promise<number> => {
  const { count, error } = await supabase
    .from("goals")
    .select("*", { count: "exact", head: true })
    .eq("user_id", userId);

  if (error) {
    throw new Error(error.message);
  }

  return count || 0;
};


// Update a goal by ID
export const updateGoal = async (
  id: string,
  updates: GoalUpdate
): Promise<Goal> => {
  const { data, error } = await supabase
    .from("goals")
    .update(updates)
    .eq("id", id)
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

// Delete a goal by ID
export const deleteGoal = async (id: string): Promise<Goal> => {
  const { data, error } = await supabase
    .from("goals")
    .delete()
    .eq("id", id)
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};
