import { Button, Card, Input, message, Rate, Skeleton, Spin } from "antd";
import MarkdownRenderer from "../../components/MarkdownRenderer";
import { useEffect, useRef, useState } from "react";
import {
  getInterviewById,
  getMessages,
  Interview,
  Message,
  updateInterview,
} from "../../api/interviews";
import { useParams } from "react-router-dom";

const Feedback = () => {
  const [loading, setLoading] = useState(false);
  const [summaryLoading, setSummaryLoading] = useState(true);
  const { interviewId } = useParams();
  const paramsRef = useRef<Interview>();
  const [rate, setRate] = useState(0);
  const [value, setValue] = useState("");
  const [messageList, setMessageList] = useState<Message[]>([]);
  const [messageLoading, setMessageLoading] = useState(true);
  const [summary, setSummary] = useState<string | null>(null);

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const onRateChange = (value: number) => {
    setRate(value);
  };

  const handleSubmit = async () => {
    if (!paramsRef.current || !interviewId)
      return message.error("params is empty");
    try {
      setLoading(true);
      const o = {
        rating: rate,
        feedback: value,
      };
      const res = await updateInterview(interviewId, o);
      message.success("Feedback submitted successfully");
      console.log(res);
    } catch (error) {
      console.error("Error launching interview:", error);
      // Optionally, show an error message to the user
    } finally {
      setLoading(false);
    }
  };

  const renderMessage = (msg: Message) => {
    const isUser = msg.role === "interviewee";
    const messageStyles = isUser
      ? "bg-orange-200 text-black self-end"
      : "bg-gray-200 text-black self-start";

    return (
      <div
        key={msg.id}
        className={`mb-4 flex ${isUser ? "justify-end" : "justify-start"}`}
      >
        <div
          className={`flex flex-col items-${
            isUser ? "end" : "start"
          } max-w-[95%]`}
        >
          <span className="text-xs text-gray-500 mb-1">
            {isUser ? "me" : msg.role || msg.speaker_id}
          </span>
          <div className={`p-4 rounded-lg ${messageStyles}`}>{msg.text}</div>
        </div>
      </div>
    );
  };

  const initData = async () => {
    if (!interviewId) return;
    try {
      const interview = await getInterviewById(interviewId);
      if (interview) {
        paramsRef.current = interview;
        if (interview.summary) {
          setSummary(interview.summary);
          setSummaryLoading(false);
        } else {
          retryLoadingSummary();
        }
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to get interview", error.message);
    }
  };

  const retryLoadingSummary = () => {
    let retryCount = 0;
    const maxRetries = 12; // 60 seconds / 5 seconds interval = 12 retries

    const retryInterval = setInterval(async () => {
      if (retryCount >= maxRetries) {
        clearInterval(retryInterval);
        setSummaryLoading(false);
        setSummary("Summary not available");
        return;
      }

      try {
        const interview = await getInterviewById(interviewId!);
        if (interview && interview.summary) {
          clearInterval(retryInterval);
          setSummary(interview.summary);
          setSummaryLoading(false);
        }
      } catch (error) {
        console.error("Error fetching summary:", error);
      }

      retryCount++;
    }, 5000);
  };

  const initMessage = async () => {
    if (!interviewId) return;
    try {
      setMessageLoading(true);
      const messages = await getMessages(interviewId);
      setMessageList(messages);
      console.log(messages);
    } catch (error) {
      console.error(error);
      message.error("Failed to get messages", error.message);
    } finally {
      setMessageLoading(false);
    }
  };

  useEffect(() => {
    initData();
    initMessage();
  }, []);

  const skeleton = [...new Array(5).keys()].map((v) => (
    <Skeleton className="h-full mt-8 " active key={v} />
  ));

  return (
    <div className="min-h-screen">
      <div className="text-xl font-bold mb-4">
        AlInterview Assistant-Interview Completed!
      </div>
      <div className="flex gap-8 h-full">
        <div className="w-1/2 flex flex-col flex-1">
          <Card
            bordered={false}
            className="shadow-none mb-4"
            style={{ boxShadow: "none" }}
          >
            <div className="mb-4">
              <div className="mb-2 text-l font-bold">How did we do?</div>
              <Rate className="text-2xl" value={rate} onChange={onRateChange} />
            </div>

            <div className="mb-2 text-l font-bold">Feedback (optional)</div>
            <Input.TextArea
              className="w-full"
              value={value}
              onChange={onChange}
              autoSize={{ minRows: 4, maxRows: 10 }}
              placeholder="Tell us more details so we can improve the product for you."
            />
            <Button
              type="primary"
              loading={loading}
              className="mt-4"
              onClick={handleSubmit}
            >
              Submit Feedback
            </Button>
          </Card>
          <Card>
            <div className="flex flex-col max-h-[calc(100vh-330px)] overflow-hidden">
              <div className="mb-4 text-xl font-bold">Interview Transcript</div>
              <div
                className="overflow-y-auto flex-1 h-full"
                key={messageLoading + "" + messageList.length}
              >
                {messageLoading ? skeleton : messageList.map(renderMessage)}
              </div>
            </div>
          </Card>
        </div>
        <Card className="flex-1 w-full overflow-hidden">
          <div className="flex max-h-screen flex-col w-full">
            <div className="mb-4 text-xl font-bold">Interview Summary</div>
            <div className="bg-gray-100 p-4 rounded-md overflow-y-auto flex-1 w-full">
              {summaryLoading ? (
                skeleton
              ) : (
                // <div className="flex flex-col gap-4 bg-white">
                //   {skeleton}
                //   {/* <Spin />
                //   <span className="ml-2">Loading summary...</span> */}
                // </div>
                <MarkdownRenderer
                  markdownText={summary || "No summary available"}
                />
              )}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Feedback;
